<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-content">
        <el-form ref="selectForm" :model="selectForm">
          <el-row>
            <el-col :span="7">
              <el-form-item label="需方名称">
                <el-input v-model.trim="selectForm.retailerName" maxlength="30" @keyup.enter.native="handleGetRetailerListByPage()" />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="供方名称">
                <el-input v-model.trim="selectForm.supplierName" maxlength="30" @keyup.enter.native="handleGetRetailerListByPage()" />
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="协同日期">
                <el-date-picker v-model="selectForm.cooperateDateRange" type="daterange" range-separator="" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" style="width: 226px; padding: 0px 10px"> </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="3" style="text-align: right">
              <el-button type="primary" @click="submitForm">查询</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <div class="content-table">
        <el-table :data="tableData" stripe border v-loading="tableLoading" :header-cell-style="handleHeaderCellStyle" style="width: 100%">
          <el-table-column type="index" label="序号" width="60"></el-table-column>
          <el-table-column prop="retailerName" label="需方名称" min-width="180"></el-table-column>
          <el-table-column prop="supplierName" label="供方名称" min-width="180"></el-table-column>
          <el-table-column prop="supplierOrgName" label="供方企业" min-width="180"></el-table-column>
          <el-table-column prop="cooperateStatus" label="协同状态">已协同</el-table-column>
          <el-table-column prop="cooperateDate" label="协同日期" :formatter="handleDateFormat"></el-table-column>
        </el-table>
      </div>
      <div class="content-page">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="page.current" :page-sizes="pageSizes" :page-size="page.size" layout="total, sizes, prev, pager, next" :total="page.total"> </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { tableStyle, pagingMethods, dateOption } from '@/util/mixins';
import { getRetailerListByPage } from '@/service/cooperate';

export default {
  name: 'Retailer',
  mixins: [tableStyle, pagingMethods, dateOption],
  data() {
    return {
      buyerImportVisible: false,
      buyerEditVisible: false,
      tableLoading: false,
      deleteSelectionsClass: '',
      deleteSelections: [],
      editTitle: '',
      selectForm: {
        retailerName: '',
        supplierName: '',
        cooperateDateRange: ''
      },
      tableData: [],
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() >= Date.now() + 0 * 24 * 3600 * 1000;
        }
      }
    };
  },
  created() {
    this.handleGetRetailerListByPage();
  },
  methods: {
    /* 构建参数，提交表单 */
    submitForm() {
      this.handleGetRetailerListByPage();
    },
    /* 分页获取需方列表 */
    async handleGetRetailerListByPage() {
      this.tableLoading = true;
      const param = {
        filter: this.selectForm,
        paging: this.page
      };
      const res = await getRetailerListByPage(param);
      this.tableLoading = false;
      if (res.success) {
        this.tableData = res.data.records;
        this.page.total = res.data.total;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content-main {
  display: flex;
  min-height: calc(100% - 120px);
  flex-direction: column;
  justify-content: space-between;

  .content-table {
    .operate-button {
      display: flex;
      justify-content: center;

      .el-button {
        padding: 0;
        margin-right: 10px;
      }
    }
  }
}
</style>
